export const MenuItems = [
  {
    title: "Add Country",
    url: "/addCountry",
  },
  {
    title: "Show Countries",
    url: "/showCountries",
  },
  {
    title: "Add Server",
    url: "/addServer",
  },
  {
    title: "Show Servers",
    url: "/ShowServers",
  },
  {
    title: "Add FAQ",
    url: "/addFaq",
  },
  {
    title: "All FAQ",
    url: "/allFaq",
  },
  {
    title: "All Blogs",
    url: "/allBlogs",
  },
  {
    title: "Add Blog",
    url: "/addBlogs",
  },
  {
    title: "Get Image Link",
    url: "/getLink",
  },
];
